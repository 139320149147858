var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('CModalExtended',{attrs:{"title":_vm.title,"color":"dark","show":_vm.modalActive,"size":"lg","closeOnBackdrop":false},on:{"update:show":[function($event){_vm.modalActive=$event},function($event){return _vm.closeModal($event)}]},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"outline":"","color":"add"},on:{"click":function($event){$event.stopPropagation();_vm.isEdit?_vm.statusConfirmation(true, _vm.Status, _vm.submit) :_vm.submit()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}}),_vm._v("  "+_vm._s(_vm.$t('button.accept'))+" ")],1),_c('CButton',{attrs:{"color":"wipe"},on:{"click":function($event){return _vm.closeModal(false)}}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "+_vm._s(_vm.$t('button.cancel'))+" ")],1)]},proxy:true}])},[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',{staticClass:"justify-content-between"},[_c('CCol',{attrs:{"sm":"10"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.cargoGroup'),"addLabelClasses":"text-right required","horizontal":{label:'col-sm-4', input:'col-sm-6'},"options":_vm.computedTpGpoGeneral,"value":_vm.TpCargoId,"is-valid":_vm.hasError(_vm.$v.TpCargoId),"invalid-feedback":_vm.errorMessage(_vm.$v.TpCargoId)},on:{"change":function($event){return _vm.gpoSelectedFilter($event)}},model:{value:(_vm.$v.TpCargoId.$model),callback:function ($$v) {_vm.$set(_vm.$v.TpCargoId, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.TpCargoId.$model"}})],1),_c('CCol',{staticClass:"justify-content-end",attrs:{"sm":"2"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.packaging'),
            placement: 'top-end',
          }),expression:"{\n            content: $t('label.packaging'),\n            placement: 'top-end',\n          }"}],attrs:{"square":"","color":"add"},on:{"click":_vm.toggleCollapse}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('label.nuevo'))+" ")])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('collpaseEmbalaje',{attrs:{"collapse":_vm.collapse,"packagingEdit":_vm.packagingEdit},on:{"update:collapse":function($event){_vm.collapse=$event},"close-collapse":_vm.closeCollapse,"refresh-packaging":_vm.getTpCargoGeneral}})],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedTpGeneralPackaging,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"checked",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CInputCheckbox',{staticClass:"checkbook-filter",attrs:{"custom":"","checked":item.checked},on:{"update:checked":function($event){return _vm.addRemoveId(item)}}})],1)]}},{key:"checked-filter",fn:function(){return [_c('td',{staticClass:"center-cell",staticStyle:{"border-top":"none"}},[_c('CInputCheckbox',{staticClass:"checkbook-filter",attrs:{"label":_vm.$t('label.all'),"custom":"","checked":_vm.AllPackaging},on:{"update:checked":function($event){return _vm.SelectAllPackaging($event)}}})],1)]},proxy:true},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.edit')+' '+_vm.$t('label.packaging'),
              placement: 'top-end',
            }),expression:"{\n              content: $t('label.edit')+' '+$t('label.packaging'),\n              placement: 'top-end',\n            }"}],attrs:{"square":"","color":"edit","size":"sm"},on:{"click":function($event){return _vm.showCollpaseEditEmbalaje(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),(!_vm.isEdit && _vm.arrIds.length === 0)?_c('p',{staticClass:"mt-2 text-danger"},[_vm._v(" "+_vm._s(_vm.$t('label.must')+' '+_vm.$t('label.toSelect')+' '+_vm.$t('label.atLeast')+' 1 '+_vm.$t('label.type')+' '+_vm.$t('label.of')+' '+_vm.$t('label.packaging'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }